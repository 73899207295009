import React, { useEffect, useState } from 'react'
import { WiDaySunny, WiCloud, WiRain, WiSnow, WiFog, WiDayThunderstorm } from 'react-icons/wi';
import axios from 'axios';
import { InputSwitch } from "primereact/inputswitch";
import useUserStore from '../../store/UserStore';
const TopWeather = ({ isChecked, onCheckedChange,totalcount }) => {
    const [weatherData, setWeatherData] = useState([]);
    const setLoading = useUserStore((state) => state.setLoading)
    const userinfo=useUserStore((state) => state.userInfo)
    const [error, setError] = useState(null);  

    useEffect(() => {
      if (userinfo?.location?.latitude && userinfo?.location?.longitude) {
        const LATITUDE = userinfo?.location?.latitude; 
        const LONGITUDE = userinfo?.location?.longitude; 

        const fetchWeatherData = async () => {
          setLoading(true);
          try {
            const response = await axios.get(
              `https://api.open-meteo.com/v1/forecast?latitude=${LATITUDE}&longitude=${LONGITUDE}&current=temperature_2m,weather_code&hourly=temperature_2m,weather_code&timezone=auto`
            );
            if (response?.status === 200) {
              setWeatherData(response?.data);
              setLoading(false);  
            }
          } catch (error) {
            setError(error?.message);
            setLoading(false);
          }
        };

        fetchWeatherData();
      } else {
        console.log("Latitude and Longitude are not defined");
      }
    }, [userinfo?.location?.latitude, userinfo?.location?.longitude, setLoading]);
    const weatherCodeToIcon = (code) => {
        const iconSize = { fontSize: '24px' }; 
        switch (code) {
            case 0:
                return <WiDaySunny style={iconSize} />;
            case 1:
            case 2:
            case 3:
                return <WiCloud style={iconSize} />;
            case 45:
            case 48:
                return <WiFog style={iconSize} />;
            case 51:
            case 53:
            case 55:
            case 61:
            case 63:
            case 65:
            case 80:
            case 81:
            case 82:
                return <WiRain style={iconSize} />;
            case 71:
            case 73:
            case 75:
            case 85:
            case 86:
                return <WiSnow style={iconSize} />;
            case 95:
            case 96:
            case 99:
                return <WiDayThunderstorm style={iconSize} />;
            default:
                return <WiDaySunny style={iconSize} />;
        }
    };
    const getNextEvenHour = (date) => {
      const nextHour = new Date(date);
      nextHour.setHours(nextHour.getHours() + 1);
      nextHour.setMinutes(0, 0, 0);
      if (nextHour.getHours() % 2 !== 0) {
        nextHour.setHours(nextHour.getHours() + 1);
      }
      return nextHour;
    };
  
    const formatTime = (timeString) => {
      const date = new Date(timeString);
      let formattedTime = date.toLocaleTimeString([], { hour: 'numeric', hour12: true });
      formattedTime = formattedTime.replace('AM', 'A.M').replace('PM', 'P.M');
      return formattedTime;
    };
  const startIndex = weatherData?.hourly?.time?.findIndex(time => new Date(time).getHours() >= getNextEvenHour(new Date()).getHours());
  const displayTimes = weatherData?.hourly?.time?.filter((_, index) => index >= startIndex && (index - startIndex) % 2 === 0).slice(0, 8);

  return (
    <div>
           <div className='surface-0 shadow-2 px-3 py-1  border-1 border-50  mb-1' style={{borderRadius:"10px"}}>
          <div className='flex justify-content-between align-items-center gap-3 '>
        <div className='flex justify-content-between align-items-center gap-4 '>
          <div className="flex justify-content-between mr-4 ">
            <div>
              <div className="uppercase text-left waethertitle" >{userinfo?.location?.name}</div>
              {weatherData?.current?.temperature_2m !== undefined && (
              <div className="text-left waethertitle-temperature" >
                {weatherData?.current?.temperature_2m}°C
              </div>
            )}
            </div>
            
          </div>
          {displayTimes?.map((time, index) => (
                            <div className="flex justify-content-between" key={index}>
                                <div>
                                    <div className="uppercase text-left weather-time" >{formatTime(time)}</div>
                                    <div className="text-left weather-tempearature pt-1" >{weatherData.hourly.temperature_2m[startIndex + index * 2]}°C</div>
                                </div>
                                <div className="mt-2 pl-2">
                                {weatherCodeToIcon(weatherData.hourly.weather_code[startIndex + index * 2])}
                                </div>
                            </div>
                        ))}
        </div>

        <div className="flex justify-content-between align-items-center gap-4">
        <div >
            <div>
              <div className=" text-left weather-time" >Open / Limited</div>
              <div className="text-left waethertitle pt-1" style={{ color:"#0ED9A0" }}>{totalcount?.Open?.count +totalcount?.Limited?.count}</div>
            </div>
            
          </div>
          <div >
            <div>
            <div className=" text-left weather-time">Closed / Missing</div>
            <div className="text-left waethertitle pt-1" style={{color:"#F50000" }}>{totalcount?.Closed?.count +totalcount?.Missing?.count}</div>
            </div>
            
          </div>
          <div >
            <div>
            <div className=" text-left" style={{ fontSize: "14px",fontWeight:"400" }}>All Stations</div>
            <div className="card flex justify-content-center">
            <InputSwitch checked={isChecked} onChange={(e) => onCheckedChange(e.value)} />
        </div>          </div>
            
          </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default TopWeather