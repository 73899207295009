import "./component/assets/css/style.scss"
import Loader from "./component/core/Loader";
import MainLayout from './component/core/MainLayout';
function App() {
  return (
    <div className="main-layout">
      <Loader/>
      <MainLayout/>
    </div>
  );
}

export default App;
