import React, { useState } from 'react';
import { Timeline } from 'primereact/timeline';
import StatusUpdateDialog from './StatusUpdateDialog';
import moment from 'moment';
const CustomTimeline = ({ events,onRefresh ,locationValue}) => {
    const [dialogVisible, setDialogVisible] = useState(false);
    const getClassByDescription = (description) => {
      if (description?.includes('Snowfall')) {
        return 'snow';
      } else if (description?.includes('Rain')) {
        return 'rain';
      } 
      else if (description?.includes('Illness')) {
        return 'illness';
      } 
      else if (description?.includes('Fire')) {
        return 'fire';
      } else {
        return '';
      }
    };
    const customizedMarker = (item) => {
      const itemClass = getClassByDescription(item.issue);
      return (
        <span className={`p-timeline-event-marker ${itemClass}`}></span>
      );
    };
    const customizedContent = (item) => {
      const itemClass = getClassByDescription(item.issue);
      return (
        <div className={`history-timeline-content  ${itemClass}`}>
          <div className='timeline-heading '><span>{item?.issue?item?.issue:"-"}</span></div>
          <div className=' timeline-desc pb-5'>
            <span className='newtimeline-text-color'>{item?.status_date}-{item?.name}</span>
            <div className='timeline-heading2  flex justify-content-between mt-2'>
              <span className='timeline-heading2'>Days Behind</span>
              <span className='timeline-heading2'>{item?.backlog}</span>
            </div>

          </div>
        </div>
      );
    };
  
const showDialog = () => {
    setDialogVisible(true);
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };
  
  const handleStatusSubmit = async (stateName, id) => {
    await onRefresh(stateName, id); 
    setDialogVisible(false); 
  };

  return (
    <div className="timeline-demo p-2" >
         
      <div className="card">
        <div className='flex justify-content-between align-items-center mb-4'>
        <span  className='text-base '>Add New Status</span>
        <div className='map-icon mt-1'>
        <i className="pi pi-plus cursor-pointer" style={{ fontSize: '1rem'}} onClick={showDialog}></i>
        </div>   
        </div>
      
       <Timeline align="left" className='' value={events} content={customizedContent} marker={customizedMarker}/>
       <StatusUpdateDialog visible={dialogVisible} onHide={hideDialog} onRefresh={handleStatusSubmit} locationValue={locationValue}/>
      </div>
    </div>
  );
};

export default CustomTimeline;
