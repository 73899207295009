import { Button } from "primereact/button";
export default function PrimaryButton({ className = '', disabled, children, ...props }) {
    return (
        <Button
            {...props}
            className={
                `px-4 py-2 text-xs flex align-items-center justify-content-center ${
                    disabled && 'opacity-25'
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </Button>
    );
}
