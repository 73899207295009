import React from 'react';


export const StatusSection = ({activeColumn, onColumnClick,totalcount }) => {
  const handleClick = (columnName) => {
    onColumnClick(columnName);
  };

  const getColumnClassName = (columnName) => {
    return activeColumn === columnName ? 'border-2' : 'border-2';
  };

  const determinepercentage=(total,count)=>{
    if (total > 0) {
      const percentage = (count / total) * 100;
      return percentage === 100 ? "100" : percentage.toFixed(2);
    }
    return "0.0";
  };
  return (
    <>  
    <div>
      <div className='surface-0 shadow-2 px-3  border-1 border-50  mb-1' style={{borderRadius:"10px"}}>
      <div className='flex justify-content-between align-items-center gap-3 my-2'>

<div className={`surface-0  p-2  cursor-pointer ${activeColumn === 'Open' ||activeColumn === 'All' ? 'border-teal-400' : 'custom-border'} ${getColumnClassName('Open')}`} onClick={() => handleClick('Open')} style={{borderRadius:"14.05px",width:'310px'}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
            <span className="block mb-3 uppercase ">Open </span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.Open?.count}</div>
            </div>
            <div className="mt-3" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#107E5F33" }}>
            <span className='percent-font' style={{color: "#107E5F" }}>{`${determinepercentage(totalcount?.Total,totalcount?.Open?.count)}%`}</span>
            </div>
          </div>
        </div>
        <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Limited' ||activeColumn === 'All' ? 'border-blue-400' : 'custom-border'} ${getColumnClassName('Limited')}`} onClick={() => handleClick('Limited')}style={{borderRadius:"14.05px",width:'310px'}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
              <span className="block mb-3 uppercase " >Limited</span>
              <div className="text-left" style={{ color:"#696969" }}>{totalcount?.Limited?.count}</div>
            </div>
            <div className="mt-3" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#238EF114" }}>
            <span className='percent-font' style={{color: "#238EF1"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Limited?.count)}%`}</span>
            </div>
          </div>
        </div>

      
        <div className={`surface-0 p-2  cursor-pointer  ${activeColumn === 'Closed' ||activeColumn === 'All' ? 'border-red-400' : 'custom-border'} ${getColumnClassName('Closed')}`} onClick={() => handleClick('Closed')} style={{borderRadius:"14.05px",width:'310px'}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
              <span className="block mb-3 uppercase ">Closed</span>
              <div className="text-left" style={{ color:"#696969" }}>{totalcount?.Closed?.count}</div>
            </div>
            <div className="mt-3" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#FF262614" }}>
              <span className='percent-font' style={{color: "#FF2626"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Closed?.count)}%`}</span>
            </div>         
          </div>
        </div> 
        <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Missing' ||activeColumn === 'All' ? 'border-gray-500' : 'custom-border'} ${getColumnClassName('Missing')}`} onClick={() => handleClick('Missing')} style={{borderRadius:"14.05px",width:'310px'}}>
          <div className="flex justify-content-between ">
            <div className='top-title-font'>
            <span className="block mb-3 uppercase">Missing</span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.Missing?.count}</div>
            </div>
            <div className="mt-3" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#80808014" }}>
              <span className='percent-font' style={{color: "#808080"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Missing?.count)}%`}</span>
            </div>
          </div>
        </div>
      </div>
      </div>
 </div>
    </>
  );
};