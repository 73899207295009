import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import InputLabel from '../../shared/InputLabel';
import TextInput from '../../shared/TextInput';
import InputError from '../../shared/InputError';
import PasswordInput from '../../shared/PasswordInput';
import PrimaryButton from '../../shared/PrimaryButton';
import ConfirmCancelModal from '../../shared/Modal/ConfirmCancelModal';
import UploadImageModal from '../../shared/Modal/UploadImageModal';
import ConfirmRemoveImageModal from '../../shared/Modal/ConfirmRemoveImageModal';    
import httpClient from '../../core/api';
import SuccessModal from '../../shared/Modal/SuccessModal';
import useUserStore from '../../../store/UserStore';
const UserProfile = () => {
  const setLoading = useUserStore((state) => state.setLoading)
  const setUserInfo = useUserStore((state) => state.setUserInfo)
  const userinfo=useUserStore((state) => state.userInfo)
  const navigate=useNavigate();
  const [userName, setUserName] = useState(userinfo?.name);
  const [userEmail, setUserEmail] = useState(userinfo?.email);
  const [dashboardPreference, setDashboardPreference] = useState(userinfo?.dashboard_preference || "All");
  const [timezones, setTimezones] = useState(userinfo?.timezone || "US/Central");
  const [totalTimezone, setTotalTimezone] = useState([]);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [homepage,setHomepage]=useState([])
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [errors, setErrors] = useState();

  const [success, setSuccess] = useState(false);
  const [flashstatus, setFlashStatus] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);
  const [profilePicture, setProfilePicture] = useState(userinfo?.profile_picture);
  const [shouldUpload, setShouldUpload] = useState(false);
  const handleSuccessClose = () => {
      setSuccess(false);
      setFlashStatus(null);
      setFlashMessage(null);
      setIsPasswordExpired(false)
  };

  useEffect(() => {
      if (userinfo?.is_password_expired) {
        setFlashStatus( "Password Expired");
        setFlashMessage("Please change your password.");
          setIsPasswordExpired(true)
          setSuccess(true);
      }
  }, [])
  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(`/api/user`);
      if(response.status===200){
          setUserInfo(response?.data)
          setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  useEffect(()=>{
    
    const fetchTimezone = async () => {
      setLoading(true)
      try {
        const response = await httpClient.get(`/api/timezones`);
        if(response?.status===200){
          setTotalTimezone(response?.data || []);
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchDashboardPreference = async () => {
      setLoading(true)
      try {
        const response = await httpClient.get(`/api/dashboard-filters`);
        if(response?.status===200){
        setHomepage(response?.data || []);
        setLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchDashboardPreference();
    fetchTimezone();
  },[])
  const handleUpload=async()=>{
    setLoading(true)
    try{
      const formData = new FormData();
      formData.append('name', userName);
    formData.append('email', userEmail);
    formData.append('timezone', timezones);
    formData.append('dashboard_preference', dashboardPreference);
    formData.append('current_password', currentPassword);
    formData.append('password', newPassword);
    formData.append('password_confirmation', confirmNewPassword);
    formData.append('profile_picture', profilePicture);
      const res =await httpClient.post('/api/profile',formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-HTTP-Method-Override': 'PATCH', 
        },
      });
      if(res.status===200){
        fetchUserData();
        setLoading(false)
        setSuccess(true);
        setFlashStatus(res?.data?.status);
        setFlashMessage(res?.data?.message);
    }
    }
    catch(error){
      console.log(error)
      setErrors(error?.response?.data?.errors)
      setLoading(false)
    }
  }
  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center grouped-header p-2 computer-timezone">
        <div className='cursor-pointer' onClick={currentTimeZone}>{option.label}</div>
      </div>
    );
  };
  const currentTimeZone = () => {
    const computerTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezones(computerTimezone);
  };
  const groupedTimezone = [
    {
      label: 'Use My Computer Timezone',
      items: totalTimezone.slice(0, 17).map(timezone => ({ label: timezone, value: timezone }))
    },
    {
      label: '',
      items: totalTimezone.slice(17).map(timezone => ({ label: timezone, value: timezone }))
    },
  ];
  const pictureTemplate = (image) => {
    if (image?.name) {
      return (
        <div className="border-circle">
          <img style={{ borderRadius: "50%" }}
            src={URL.createObjectURL(image)}
            alt="Selected File"
            className="dashboard-file-preview"
            width={100}
            height={100}
          />
        </div>
      )
    }
    else {
      return (
        <div className="border-circle " >
          <img
            style={{ borderRadius: "50%", }}
            src={`${image}`}
            alt="Profile Picture"
            width={100}
            height={100}
          />
        </div>
      )
    }
  }
  const [showUploadImageModal, setshowUploadImageModal] = useState(false);
  const [fileValidationMessage, setFileValidationMessage] = useState({
    error: false,
    message:null
  });
  const [isDefaultPic, setIsDefaultPic] = useState(userinfo?.profile_picture?.name ? false : userinfo?.profile_picture?.includes("customer.png"));
  const [showRemoveImageModal, setShowRemoveImageModal] = useState(false);
  const [showCancelModal, setshowCancelModal] = useState(false);
  const handleGoBack = () => {
    setshowCancelModal(true)
  };
  const handleNavigateBack = () => {
    navigate("/")
  };
  const updateProfileImage = (file) => {
    setProfilePicture(file)
    setShouldUpload(true); 
  };
  useEffect(() => {
    if (shouldUpload && profilePicture) {
      handleUpload();
      setShouldUpload(false); 
    }
  }, [shouldUpload, profilePicture]);
  const removeProfileImage = async() => {
    setLoading(true)
    try {
      const res = await httpClient.delete(`/api/profile-picture`);
      if(res?.status===200){
        fetchUserData();
        setLoading(false)
        setSuccess(true);
        setFlashStatus(res?.data?.status);
        setFlashMessage(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  useEffect(()=>{
    setIsDefaultPic(userinfo?.profile_picture?.name ? false : userinfo?.profile_picture?.includes("customer.png"))
  },[userinfo])
  return (
    <div>
      <Panel header="User Profile" className="p-panel-profile font-medium">
      <div className="mx-4 px-4">
      <div className="rounded-md p-4 shadow-md">
          <h2 className="text-xl main-heading mb-2">Profile Settings</h2>
          <div className='flex'>
            <div style={{ width: "60%" }}>
              <div className="flex my-4">
                <div className="mr-4 flex-1 custom-max-width">
                  <InputLabel htmlFor="name" className="dim-text" value="User Name:" />
                  <TextInput 
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    value={userName}
                    className="mt-1 block w-full"
                    autoComplete="name"
                    isFocused={true}
                    onChange={(e) => {setUserName(e.target.value);setIsFormChanged(true)}}
                  />
                  <InputError message={errors?.name} className="mt-2" />
                </div>

                <div className="mr-4 flex-1 custom-max-width">
                  <InputLabel htmlFor="email" className="dim-text" value="Email:" />
                  <TextInput
                    id="email"
                    type="email"
                    name="email"
                    disabled
                    placeholder="Enter Email"
                    value={userEmail}
                    className="mt-1 block w-12"
                    autoComplete="username"
                    onChange={(e) =>{ setUserEmail(e.target.value);setIsFormChanged(true)}}
                  />
                  <InputError message={errors?.email} className="mt-2" />
                </div>
              </div>

              <div className="flex my-4">
                <div className="mr-4 flex-1 custom-max-width">
                  <InputLabel htmlFor="timezone" className="dim-text" value="Timezone:" />
                  <Dropdown
                    inputId='timezone'
                    name="timezone"
                    value={timezones}
                    options={groupedTimezone}
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionGroupTemplate={groupedItemTemplate}
                    className="mt-1 p-2 border rounded-md w-full"
                    onChange={(e) => {setTimezones(e.value);setIsFormChanged(true)}}
                    filterPlaceholder="Search Time Zone"
                    filter
                    resetFilterOnHide
                    style={{ fontWeight: "bold", textAlign: "left" }} />
                  {errors?.timezone && <div className="text-sm text-red-600 mt-2">{errors?.timezone}</div>}
                </div>

                <div className="mr-4 flex-1 custom-max-width">
                  <InputLabel htmlFor="dashboard" className="dim-text" value="Select Homepage View:" />
                  <Dropdown
                    inputId='dashboard'
                    name="dashboard"
                    value={dashboardPreference}
                    options={homepage}
                    onChange={(e) => {setDashboardPreference(e.value);setIsFormChanged(true)}}
                    className="mt-1 p-2 border rounded-md w-full"
                    style={{ fontWeight: "bold", textAlign: "left" }}
                  />
                  {errors?.dashboard_preference && <div className="text-sm text-red-600 mt-2">{errors?.dashboard_preference}</div>}
                </div>
              </div>

              <div className="rounded-md ">
                <h2 className="text-xl main-heading mb-2">Change Password</h2>
                <div className="grid my-4">
                  <div className="col-4 mr-4 flex-1 custom-max-width">
                    <InputLabel htmlFor="current_password" className="dim-text" value="Current Password:" />
                    <PasswordInput
                      id="current_password"
                      type="password"
                      name="current_password"
                      placeholder="Current Password"
                      value={currentPassword}
                      className="mt-1 block w-full"
                      autoComplete="new-password"
                      onChange={(e) => {setCurrentPassword(e.target.value);setIsFormChanged(true)}}
                    />
                    <InputError message={errors?.current_password} className="mt-2" />
                  </div>
                  <div className="col-4 mr-4 flex-1 custom-max-width">
                  </div>
                </div>

                <div className="grid my-4">
                  <div className="mr-4 col-4 flex-1 custom-max-width">
                    <InputLabel htmlFor="password" className="dim-text" value="New Password:" />
                    <PasswordInput
                      id="password"
                      type="password"
                      name="password"
                      placeholder="New Password"
                      value={newPassword}
                      className="mt-1 block w-full"
                      autoComplete="new-password"
                      onChange={(e) => {setNewPassword(e.target.value);setIsFormChanged(true)}}
                    />
                    <InputError message={errors?.password} className="mt-2" />
                  </div>

                  <div className="mr-4 col-4 flex-1 custom-max-width">
                    <InputLabel htmlFor="password_confirmation" className="dim-text" value="Confirm New Password:" />
                    <PasswordInput
                      id="password_confirmation"
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      className="mt-1 block w-full"
                      autoComplete="new-password"
                      onChange={(e) => {setConfirmNewPassword(e.target.value);setIsFormChanged(true)}}                  />
                    <InputError message={errors?.password_confirmation} className="mt-2" />
                  </div>
                </div>

                <div className="grid">
                  <div className='col-8'>
                    <div className='flex align-items-right '>
                      <PrimaryButton type='button' onClick={(e) => { handleGoBack(e) }} className=" secondary-button md:text-lg text-base mr-1 md:mr-2" disabled={false}>
                        Cancel
                      </PrimaryButton>
                      <PrimaryButton type='submit' className="w-full lg:w-1 md:w-3 primary-button md:text-lg text-base " disabled={!isFormChanged} onClick={handleUpload}>
                        Update
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div> 
            </div>

            <div style={{ width: "40%", }}>
              <div className="flex flex-column justify-content-center align-items-center w-6" >
                <div className="text-xl" ><p className="label-text" >Upload Image</p></div>
                <div className='mb-2'>
                  {
                    pictureTemplate(userinfo?.profile_picture)
                  }
                </div>
                <PrimaryButton className='upload-button primary-button' type="button" onClick={(e) => {
                  setshowUploadImageModal(true);
                }}>{!isDefaultPic ? "Update Image" : "Upload Image"}</PrimaryButton>
                <div>
                  {!isDefaultPic ? <PrimaryButton id="removeBtn" type="button" className='mt-2 px-2 py-2 rejection-button' onClick={(e) => {
                    setShowRemoveImageModal(true);
                  }}
                    label="Remove Image"rounded /> : <></>}
                </div>
                <p className={`text-center text-xs light-text ${fileValidationMessage.error && 'text-red-500'}`}>
                  {fileValidationMessage.message == "customer.png" ? "No file Uploaded" : fileValidationMessage.message}
                </p>
              </div>
            </div> 

          </div>
        </div>
        </div>
      </Panel>
      <SuccessModal
                        visible={success}
                        setVisible={handleSuccessClose}
                        title={flashstatus}
                        text={flashMessage}
                        width="25vw"
                        iconName={isPasswordExpired ? 'warning' : 'tick'}
                    />
      <ConfirmCancelModal visible={showCancelModal} setVisible={setshowCancelModal} width="30vw" navigateTo={handleNavigateBack} />
      <ConfirmRemoveImageModal onConfirmRemove={removeProfileImage} visible={showRemoveImageModal} setVisible={setShowRemoveImageModal} width="30vw" redirectTo={'profile'} />
      <UploadImageModal visible={showUploadImageModal} setVisible={setshowUploadImageModal} width="30vw" onFileUpload={(file) => updateProfileImage(file)} />

    </div>
  )
}

export default UserProfile
