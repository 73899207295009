import React from "react";
import { Dialog } from 'primereact/dialog';
import PrimaryButton from "../PrimaryButton";
import Icon from "../Icon";
export default function ConfirmCancelModal({ visible, setVisible, title, id, redirectTo, width = '30vw', onConfirmRemove }) {
    const onConfirm = (e) => {
        e.preventDefault();
        closeModal();
        onConfirmRemove();
    };

    const closeModal = () => {
        setVisible(false);
    }

    return (
        <div className="card flex justify-content-center align-items-center">
            <Dialog className="custom-modal" visible={visible} modal={true} style={{ width: width }} onHide={closeModal} closable closeOnEscape dismissableMask closeIcon={  <Icon name='cross-black' type='svg' />} draggable={false}>
                <div className="flex flex-column align-items-center">
                <Icon name='warning' type='svg' />
                    <h2 className="main-heading text-lg md:text-2xl font-bold">
                        Are you sure?
                    </h2>
                    <p className="mt-0 main-text font-normal text-lg line-height-3">
                        Are you sure you want to remove?
                    </p>
                </div>
                <div className="mt-2 flex justify-content-center">
                    <PrimaryButton className='tertiary-button md:text-base text-sm' onClick={closeModal}>No</PrimaryButton>
                    <form onSubmit={onConfirm}>
                        <PrimaryButton className="ml-2 primary-button md:text-base text-sm">
                            Yes
                        </PrimaryButton>
                    </form>
                </div>
            </Dialog >
        </div >
    )
}
