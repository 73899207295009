import React, { useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import Icon from "../Icon";


export default function SuccessModal({ visible, iconName ='tick', setVisible, title, text, width = '50vw' }) {
    const closeModal = () => {
        setVisible(false);
    }
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                closeModal();
            }, 1000); 

            return () => clearTimeout(timer); 
        }
    }, [visible]);
    return (
        <div className="card flex justify-content-center align-items-center">
            <Dialog visible={visible} modal={true} style={{ width: width }} onHide={closeModal} closable closeOnEscape dismissableMask closeIcon={<Icon type="svg" name="cross-black" ></Icon>} draggable={false}>
                <div className="flex flex-column align-items-center">
                    <Icon type="svg" name={iconName} className='' />
                    <h2 className="main-heading text-lg md:text-2xl font-bold">
                        {title}
                    </h2>
                    <p className="mt-0 main-text font-normal text-lg line-height-3 text-center">
                        {text}
                    </p>
                </div>
            </Dialog>
        </div>
    )
}