import { forwardRef, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';

export default forwardRef(function TextInput({ type = 'text', className = '', isFocused = false, min, max, ...props }, ref) {
    const localRef = useRef();
    const input = ref || localRef;

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    const handleChange = (e) => {
        const inputValue = type === 'number' ? parseFloat(e.target.value) : e.target.value;
        const updatedValue = type === 'number' ? Math.min(Math.max(inputValue, min), max) : inputValue;
        props.onChange({ ...e, target: { ...e.target, value: updatedValue } });
    };

    return (
        <InputText
            {...props}
            type={type}
            min={type === 'number' ? min : undefined}
            max={type === 'number' ? max : undefined}
            className={
                'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm ' +
                className
            }
            ref={input}
            onChange={handleChange}
        />
    );
});
