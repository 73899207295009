import React from 'react';
import useUserStore from '../../store/UserStore';

const Loader = () => {
    const loading=useUserStore((state) => state.loading)
  return (
    <>    {loading &&(
    <div className={"custom-loader z-5 "}>
      <div class="spinner ">
  <div class="bounce1"></div>
  <div class="bounce2"></div>
  <div class="bounce3"></div>
</div>
    </div>
)}
</>

  );
};

export default Loader;
