export default function ApplicationLogoWhite(props) {
  return (
    <svg {...props} width="100" height="50" viewBox="0 0 757 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M353.2 185.7H344.2V226.3H369.7V218H353.1V185.7H353.2ZM409.9 219.1C402.2 219.1 397.3 213.6 397.3 206C397.3 198.4 402.3 192.9 409.9 192.9C417.5 192.9 422.5 198.4 422.5 206C422.5 213.6 417.5 219.1 409.9 219.1ZM409.9 184.7C397.2 184.7 388 193 388 206.1C388 219.2 397.3 227.5 409.9 227.5C422.5 227.5 431.8 219.2 431.8 206.1C431.8 193 422.6 184.7 409.9 184.7ZM474.2 210.4H481.5V217C479.8 217.9 477 219 473.1 219C465.4 219 460.5 213.5 460.5 205.9C460.5 198.3 465.5 192.8 473.1 192.8C477.6 192.8 480.6 194.1 483 196.6L489.3 189.7C485.2 185.9 479.6 184.5 473.1 184.5C460.4 184.5 451.2 192.8 451.2 205.9C451.2 219 460.5 227.3 473.1 227.3C479.1 227.3 484.9 226.2 490.1 223.4V202.2H474.2V210.4ZM513.3 185.7H522.3V226.3H513.3V185.7ZM553.4 197.1C553.4 194 556.9 192.9 559.4 192.9C561.6 192.9 564.4 193.7 565.9 195.6L572.1 188.9C568.6 185.8 564.2 184.7 559.7 184.7C551.7 184.7 544.2 189.2 544.2 197.9C544.2 211.4 563.3 207.4 563.3 214.9C563.3 217.8 560 219.1 557.1 219.1C554.2 219.1 551.1 217.7 549.3 215.3L542.9 222.3C546.7 225.9 551.3 227.4 556.5 227.4C564.9 227.4 572.2 223.1 572.2 214C572.1 199.7 553.4 204.5 553.4 197.1ZM590.5 193.6H602V226.3H611V193.6H622.6V185.7H590.5V193.6ZM642.5 185.7H651.5V226.3H642.5V185.7ZM693.6 219.1C687.1 219.1 682.1 213.6 682.1 206C682.1 198.4 687.1 192.9 693.9 192.9C697.2 192.9 699.8 194.1 701.6 196.2L708.5 190.6C705 186.2 699.3 184.6 694.8 184.6C682.1 184.6 672.9 192.9 672.9 206C672.9 219.1 682.1 227.4 694.8 227.4C700.4 227.4 706.1 225.2 709.5 220.3L702 214.7C700.1 217.4 697.2 219.1 693.6 219.1ZM737.3 197.1C737.3 194 740.8 192.9 743.3 192.9C745.5 192.9 748.3 193.7 749.8 195.6L756 188.9C752.5 185.8 748.1 184.7 743.6 184.7C735.6 184.7 728.1 189.2 728.1 197.9C728.1 211.4 747.2 207.4 747.2 214.9C747.2 217.8 743.9 219.1 741 219.1C738.1 219.1 735 217.7 733.2 215.3L726.8 222.3C730.6 225.9 735.2 227.4 740.4 227.4C748.8 227.4 756.1 223.1 756.1 214C756 199.7 737.3 204.5 737.3 197.1Z" fill="white" />
      <path d="M756 149.9L684.8 35.2L684.5 34.7L674.4 18.4C673.7 17.2 672.8 16.1 671.9 15C664.7 5.6 653.7 0 641.7 0H638.5C626.5 0 615.5 5.5 608.2 15C607.3 16.1 606.5 17.2 605.8 18.4L595.7 34.7L523.6 149.9H664.8L628.4 2.70001L720.1 149.9H756Z" fill="#E1261C" />
      <path d="M0 57.7996V94.5996C0 125.1 24.8 149.9 55.3 149.9H161.2V119.3H55.3C41.7 119.3 30.5 108.2 30.5 94.4996V57.6996C30.5 44.0996 41.6 32.8996 55.3 32.8996H161.6V2.29962H55.3C24.8 2.39962 0 27.2996 0 57.7996ZM544.4 2.39963L471.9 118.5C469.8 121.8 466.5 122.2 465.1 122.2C463.8 122.2 460.4 121.8 458.4 118.4L388.3 2.39963H353.1L432.6 134.1C439.5 145.5 451.6 152.3 464.8 152.5H465.2C478.4 152.5 490.4 145.9 497.4 134.7L580 2.39963H544.4ZM210.1 57.7996C210.1 44.1996 221.2 32.9996 234.9 32.9996H303.3C316.7 32.9996 317.1 45.1996 317.1 46.4996V48.3996C317.1 50.6996 316.5 62.0996 304.4 62.0996H210.1V57.7996ZM341.7 119.3H234.8C221.1 119.3 210 107.5 210 93.7996V92.4996L303.1 92.3996C331.9 92.3996 347.4 69.8996 347.4 48.6996V45.8996L347.5 45.7996C347.5 24.5996 332 2.09961 303.2 2.09961H234.7C204.2 2.09961 179.4 26.8996 179.4 57.3996V93.4996C179.4 124 204.2 149.5 234.7 149.5H341.6L341.7 119.3Z" fill="white" />
    </svg>
    
  );
  }
  