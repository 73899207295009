import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';


export default function NotificationOverlay({ notifications, unread_notifications, ...props }) {

    const getTime = (date) => {
        const specificDate = new Date(date);
        const currentDate = new Date();
        const timeDifferenceInMilliseconds = currentDate - specificDate;
        const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
        const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
        const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

        // Generate the human-readable time difference string
        if (minutes < 60) {
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else if (hours < 24) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        }
    }

    const onClickView = (notification) => {
        // get(route('mark-as-read', { id: notification?.id }))
    }

    const onClickViewAll = () => {
        // get(route('notifications'))
    }

    const onClickMarkAsRead = () => {
        // notifications?.length && unread_notifications?.length && get(route('mark-as-read-all'), {
        //     preserveState: true,
        // })
        // setIsNotif(true)
    }

    const templateHeader = (
        <div className='notification-header text-lg font-medium'>
            <span>Notifications</span>
        </div>
    );
    const templateFooter = (
        <div onClick={() => onClickViewAll()} className='underline cursor-pointer text-black-alpha-90 text-sm text-center pt-2'>View All</div >
    );
    // console.log(unread_notifications);
    return (
        <div className='notification pb-3 relative'>
            {templateHeader}
            <span onClick={() => onClickMarkAsRead()} className={`mark-as-read underline text-black-alpha-90 text-sm select-none ${notifications?.length && unread_notifications?.length ? 'cursor-pointer' : 'cursor-default opacity-50'}`}>Mark all as read</span >

            <TabView className='notification-tabview'>
                <TabPanel header="All">
                    <div className='notification-body overflow-y-scroll'>
                        {
                            notifications?.length ?
                                notifications?.map((notification, index) => {
                                    return (
                                        <div onClick={() => onClickView(notification)} key={index} className={`${notification.read_at ? 'read' : 'unread'} px-3 cursor-pointer`}>
                                            <div className='pt-3 pb-2 flex justify-content-between'>
                                                <div className='flex flex-column'>
                                                    <span className=' text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</span>
                                                    <span className=' text-gray-500 text-sm font-normal'>{getTime(notification?.created_at)}</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                                :
                                <div className={`read px-3`}>
                                    <div className='pt-3 pb-2 flex justify-content-center'>
                                        <span className='text-gray-500 text-base font-normal'>
                                            No Notifications
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </TabPanel>
                <TabPanel header="Unread">
                    <div className='notification-body overflow-y-scroll'>

                        {
                            
                            unread_notifications?.length ?
                                unread_notifications?.map((notification, index) => {
                                    return (
                                        <div onClick={() => onClickView(notification)} key={index} className={`${notification.read_at ? 'read' : 'unread'} px-3 cursor-pointer`}>
                                            <div className='pt-3 pb-2 flex justify-content-between'>
                                                <div className='flex flex-column'>
                                                    <span className='text-black-alpha-90 text-sm font-bold'>{notification?.data?.message}</span>
                                                    <span className='text-gray-500 text-sm font-normal'>{getTime(notification?.created_at)}</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                                :
                                <div className={`read px-3`}>
                                    <div className='pt-3 pb-2 flex justify-content-center'>
                                        <span className='text-gray-500 text-base font-normal'>
                                            No New Notifications
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </TabPanel>

            </TabView>
            {templateFooter}
        </div>
    );
}
