import React from 'react';
const Icon = (props) => {
    const { type, name,width,height, ...rest } = props;
    const imagePath = `/icons/${name}.${type}`;
    if (type === 'svg' || type === 'png') {
        return (
            <span {...rest}>
                <img src={imagePath} alt={`Icon: ${name}`} width={width} height={height}/>
            </span>
        );
    } else {
        return null;
    }
};
export default Icon;
