import { create } from 'zustand'

const useUserStore = create((set) => ({
  userInfo: null,
  loading: false,

  setUserInfo: (info) => set({ userInfo: info }),
  setLoading: (isLoading) => set({ loading: isLoading }),
}));

export default useUserStore;
