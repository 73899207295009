import React from 'react'
import Navbar from './Navbar'
import Approutes from '../router/Approutes'

const MainLayout = ({children}) => {
  return (
    <div>
      <Navbar/>
      <div className=''>
      <Approutes/>
      </div>
    </div>
  )
}

export default MainLayout
