import React from 'react'
import { Route, Routes } from "react-router-dom";
import USMap from '../pages/Map/Map';
import UserProfile from '../pages/user-profile/Profile';
const Approutes = () => {
  return (
    <div>
      <Routes>
      <Route path="/" element={<USMap/>}></Route>
      <Route path="/profile" element={<UserProfile/>}></Route>
      </Routes>
    </div>
  )
}

export default Approutes
