import React from "react";
import { Dialog } from 'primereact/dialog';
import PrimaryButton from "../PrimaryButton";
import Icon from "../Icon";
export default function ConfirmStatusModal({ visible, setVisible, title, id, submit, width = '30vw' }) {
    const onConfirm = (e) => {
        e.preventDefault();
        submit();
        closeModal();
    };

    const closeModal = () => {
        setVisible(false);
    }

    return (
        <div className="card flex justify-content-center align-items-center">
            <Dialog className="custom-modal" visible={visible} modal={true} style={{ width: width, }} onHide={closeModal} closable closeOnEscape dismissableMask closeIcon={ <Icon name='cross-black' type='svg' />} draggable={false}>
                <div className="flex flex-column align-items-center">
                <Icon name='warning' type='svg' />
                    <h2 className="confirm-title">
                       {title}
                    </h2>
                    <p className="mt-0 confirm-para  line-height-1">
                    Are you sure you want to submit {title} status?
                    </p>
                </div>
                <div className="mt-2 flex justify-content-center">
                    <PrimaryButton className='tertiary-button' onClick={closeModal}>Cancel</PrimaryButton>
                    <form onSubmit={onConfirm}>
                        <PrimaryButton className="ml-2 primary-button">
                            Submit
                        </PrimaryButton>
                    </form>
                </div>
            </Dialog >
        </div >
    )
}
